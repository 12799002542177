var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "RealTimeScheduling" },
    [
      _c(
        "div",
        { staticClass: "top-search" },
        [
          _c("scheduling-header", {
            on: { queryCurrentLine: _vm.queryCurrentLine }
          })
        ],
        1
      ),
      _c("scheduling-view", {
        ref: "schedulingView",
        attrs: { lineCode: _vm.lineCode, activeName: _vm.activeName },
        on: {
          sendPlanText: _vm.sendPlanText,
          showCarDetail: _vm.showCarDetail,
          sendMessage: _vm.sendMessage,
          changeDriverPop: _vm.changeDriverPop,
          manualStart: _vm.manualStart,
          changeStatus: _vm.changeStatus
        }
      }),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } },
            [
              _c("scheduling-bottom-table", {
                ref: "schedulingBottomTable",
                attrs: { lineCode: _vm.lineCode },
                on: {
                  showBusPlan: _vm.showBusPlan,
                  getCheckedData: _vm.getCheckedData,
                  sendMessage: _vm.sendMessage,
                  sendPlanText: _vm.sendPlanText,
                  changeDriver: _vm.changeDriver,
                  changeStatusSelect: _vm.changeStatusSelect,
                  changeAverage: _vm.changeAverage,
                  manualStartTogether: _vm.manualStartTogether,
                  addShiftOrTripDia: function() {
                    return (_vm.addShiftOrTripDia = true)
                  }
                }
              })
            ],
            1
          ),
          _c("el-col", { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } }, [
            _c(
              "div",
              { staticClass: "grid-content" },
              [
                _c(
                  "el-tabs",
                  { attrs: { type: "border-card" } },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "消息列表" } },
                      [
                        _c(
                          "el-table",
                          {
                            staticClass: "basicBox dispatchMonitoring",
                            attrs: {
                              data: _vm.messageList,
                              height: _vm.heightBottom,
                              stripe: "",
                              "cell-style": { color: "#000", fontSize: "16px" },
                              "header-cell-style": {
                                background: "#FAFAFA",
                                color: "#999999"
                              }
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "排序",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "cph", label: "车辆" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#f48b41" } },
                                        [_vm._v(_vm._s(scope.row.cph.slice(2)))]
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { label: "线路", prop: "companyName" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "content",
                                label: "消息内容",
                                width: "180",
                                "show-overflow-tooltip": ""
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "type", label: "消息类型" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "createTime",
                                label: "时间",
                                width: "180"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "status", label: "状态" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("filterStatus")(
                                              scope.row.status
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "到离站信息" } },
                      [
                        _c(
                          "el-table",
                          {
                            staticClass: "basicBox dispatchMonitoring",
                            attrs: {
                              data: _vm.arriveAndLeaveStationList,
                              height: _vm.heightBottom,
                              stripe: "",
                              "cell-style": { color: "#000", fontSize: "16px" },
                              "header-cell-style": {
                                background: "#FAFAFA",
                                color: "#999999"
                              }
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "排序",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "cph", label: "车辆" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#f48b41" } },
                                        [_vm._v(_vm._s(scope.row.cph.slice(2)))]
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { label: "线路", prop: "companyName" }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "businessType", label: "方向" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.businessType === 1
                                              ? "上行"
                                              : "下行"
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "gpsTime",
                                label: "时间",
                                width: "180"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "arriveType", label: "类型" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.arriveType === 1
                                              ? "到站"
                                              : "离站"
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "stationName",
                                label: "站点名称",
                                width: "160",
                                "show-overflow-tooltip": true
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "realityDriverName",
                                label: "当班司机"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v("车辆请求("),
                            _c(
                              "span",
                              {
                                style: {
                                  color:
                                    _vm.requestlistNeedComprate > 0 ? "red" : ""
                                }
                              },
                              [_vm._v(_vm._s(_vm.requestlistNeedComprate))]
                            ),
                            _vm._v(")")
                          ]
                        ),
                        _c(
                          "el-table",
                          {
                            staticClass: "basicBox dispatchMonitoring",
                            attrs: {
                              data: _vm.requestlist,
                              height: _vm.heightBottom,
                              stripe: "",
                              "cell-style": { color: "#000", fontSize: "16px" },
                              "header-cell-style": {
                                background: "#FAFAFA",
                                color: "#999999"
                              }
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "cph",
                                label: "车辆",
                                width: "100"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("div", { staticClass: "flex-style" }, [
                                        _c("i", {
                                          class: _vm.getBusIcon(
                                            scope.row.isOnline
                                          )
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.cph.slice(2)))
                                        ])
                                      ])
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { label: "线路", prop: "companyName" }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "driverName", label: "司机" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "eventId",
                                label: "消息内容",
                                width: "120"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          (
                                            _vm.natureOptions.find(function(t) {
                                              return (
                                                t.dictCode == scope.row.eventId
                                              )
                                            }) || { dictValue: "其他" }
                                          ).dictValue
                                        )
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "insertTime",
                                label: "时间",
                                width: "180"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "opStats",
                                label: "状态",
                                width: "120"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          style: {
                                            color:
                                              scope.row.opStats == 2
                                                ? "#f74242"
                                                : ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("filteropStats")(
                                                  scope.row.opStats
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "action",
                                label: "操作",
                                width: "100"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("div", { staticClass: "action" }, [
                                        scope.row.opStats !== 1 &&
                                        scope.row.opStats !== 2
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "agree",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.agreeAndReject(
                                                      scope.row.id,
                                                      1
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("同意")]
                                            )
                                          : _vm._e(),
                                        scope.row.opStats !== 1 &&
                                        scope.row.opStats !== 2
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "reject",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.agreeAndReject(
                                                      scope.row.id,
                                                      2
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("拒绝")]
                                            )
                                          : _vm._e()
                                      ])
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c("sendMsg", {
                attrs: { lineId: _vm.lineCode },
                on: {
                  closeDia: function() {
                    return (_vm.dialogVisible = false)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.driverdialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "计划调整",
                visible: _vm.driverdialogVisible,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.driverdialogVisible = $event
                }
              }
            },
            [
              _c("driverPlan", {
                attrs: {
                  updateform: _vm.updateform,
                  nowBusByline: _vm.nowBusByline,
                  nowDriverByline: _vm.nowDriverByline,
                  isPlan: _vm.isPlan
                },
                on: { closeDia: _vm.closeDiaChange }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.schedulingVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "单车发班表",
                visible: _vm.schedulingVisible,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.schedulingVisible = $event
                }
              }
            },
            [
              _c("schedulingPlan", {
                attrs: {
                  busPlanList: _vm.busPlanList,
                  busCode: _vm.busCode,
                  banCiMoveType: _vm.banCiMoveType
                },
                on: {
                  closeDia: function() {
                    return (_vm.schedulingVisible = false)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.addShiftOrTripDia
        ? _c(
            "el-dialog",
            {
              staticClass: "addShiftOrTripDia",
              attrs: {
                title: "添加班次/车次",
                visible: _vm.addShiftOrTripDia,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.addShiftOrTripDia = $event
                },
                close: _vm.cancleAddShiftOrTripDia,
                open: function($event) {
                  _vm.addShiftOrTripDia = true
                }
              }
            },
            [
              _c("addShiftOrTrip", {
                ref: "addShiftOrTripRefs",
                on: {
                  closeDia: function() {
                    return (_vm.addShiftOrTripDia = false)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.changeStatusVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "addShiftOrTripDia",
              attrs: {
                title: "修改车辆状态",
                visible: _vm.changeStatusVisible,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.changeStatusVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "statusForm",
                  staticClass: "demo-form dialog-form",
                  attrs: {
                    model: _vm.statusForm,
                    "label-width": "102px",
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车次状态：", prop: "state" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "选择车次状态" },
                          model: {
                            value: _vm.statusForm.state,
                            callback: function($$v) {
                              _vm.$set(_vm.statusForm, "state", $$v)
                            },
                            expression: "statusForm.state"
                          }
                        },
                        _vm._l(_vm.natureOptions, function(item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: Number(item.dictCode)
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.changeStatusVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.saveStatus }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dialogTableVisible
        ? _c("car-dialog", {
            attrs: {
              titlex: _vm.plate,
              id: _vm.plate,
              area: _vm.area,
              street: _vm.street,
              checkObj: _vm.carDetailObj,
              Upgrades: true
            },
            on: {
              dialogVisible: function() {
                return (_vm.dialogTableVisible = false)
              }
            }
          })
        : _vm._e(),
      _vm.averagdialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "排班匀点",
                visible: _vm.averagdialogVisible,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.averagdialogVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form search-area" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "averageform",
                      staticClass: "demo-form dialog-form",
                      attrs: {
                        model: _vm.averageform,
                        "status-icon": "",
                        rules: _vm.averagerules,
                        "label-position": "left",
                        "label-width": "102px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "400px" },
                          attrs: { label: "匀点方向", prop: "direction" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择方向" },
                              model: {
                                value: _vm.averageform.direction,
                                callback: function($$v) {
                                  _vm.$set(_vm.averageform, "direction", $$v)
                                },
                                expression: "averageform.direction"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "向前", value: "1" }
                              }),
                              _c("el-option", {
                                attrs: { label: "向后", value: "2" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "400px" },
                          attrs: { label: "匀点数", prop: "spotCount" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.averageform.spotCount,
                              callback: function($$v) {
                                _vm.$set(_vm.averageform, "spotCount", $$v)
                              },
                              expression: "averageform.spotCount"
                            }
                          }),
                          _c("span", { staticClass: "numberNotice" }, [
                            _vm._v(
                              "操作说明：匀点车次相邻车次的匀点数为1，以此类推匀点数递增"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buttonGroup text-right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "default" },
                      on: {
                        click: function($event) {
                          _vm.averagdialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.saveaverage }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }