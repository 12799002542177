<template>
  <div class="scheduling-bottom-table">
    <div class="hideBox" :style="TabBtnStyle">
      <span class="narrow" v-if="buttonFlag" @click="narrow">
        <i class="iconfont its_zhankai" />
      </span>
      <span class="enlarge" v-if="!buttonFlag" @click="enlarge">
        <i class="iconfont its_zhedie" />
      </span>
    </div>
    <el-tabs
      v-model="active"
      type="border-card"
      :style="TabStyle"
      @tab-click="handleClick"
    >
      <el-tab-pane name="dfc" label="未完成">
        <div class="top-content">
          <div ref="btns" class="btns">
            <el-button
              size="mini"
              type="primary"
              class="btn"
              @click="sendMessage"
              >消息下发</el-button
            >
            <el-button
              size="mini"
              type="primary"
              class="btn"
              @click="sendPlanText"
              >下发计划</el-button
            >
            <el-button
              size="mini"
              type="primary"
              class="btn"
              @click="changeDriver"
              >调整计划</el-button
            >
            <el-button
              size="mini"
              type="primary"
              class="btn"
              @click="addShiftOrTripDia"
              >增加计划</el-button
            >
            <el-button
              size="mini"
              type="primary"
              class="btn"
              @click="manualStartTogether"
              >手动发车</el-button
            >
            <el-button
              size="mini"
              type="primary"
              class="btn"
              @click="changeStatusSelect"
              >修改状态</el-button
            >
            <el-button
              size="mini"
              type="primary"
              class="btn"
              @click="changeAverage"
              >匀点</el-button
            >
          </div>
          <div class="search">
            <car-tree
              @getData="getVehIds"
              :limit="1"
              ref="carTreeWait"
              label="vehicleNo"
              size="mini"
            ></car-tree>
            <el-select
              v-model="moveType"
              size="mini"
              placeholder="请选择"
              class="self-select-width"
              @change="selectMoveType(1)"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-table
          class="basicBox dispatchMonitoring"
          :data="startPlanDetailList"
          @selection-change="getCheckedData"
          :height="heightBottom"
          stripe
          :cell-style="{ color: '#000', fontSize: '16px' }"
          :header-cell-style="{ background: '#FAFAFA', color: '#999999' }"
          ref="tablePlan"
        >
          <el-table-column
            type="selection"
            width="55"
            :selectable="checkboxT"
            disabled="true"
          ></el-table-column>
          <el-table-column prop="cph" label="车辆">
            <template slot-scope="scope">
              <span class="column-color" @click="showBusPlan(scope.row)">{{
                scope.row.cph.slice(2)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="driverName" label="驾驶员"></el-table-column>
          <el-table-column prop="moveType" label="方向">
            <template slot-scope="scope">
              <span>{{ scope.row.moveType == 1 ? '上行' : '下行' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="beginTime" label="计划发车"></el-table-column>
          <el-table-column prop="endTime" label="计划到达"></el-table-column>
          <el-table-column prop="busStatus" label="行车状态">
            <template slot-scope="scope">
              <span :style="{ color: busStatusColor(scope.row.busStatus) }">{{
                scope.row.busStatus | filterBusState
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="adjustStatus" label="计划状态">
            <template slot-scope="scope">
              {{ scope.row.adjustStatus | filterAdjustStatusState }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane name="ywc" label="已完成">
        <div class="top-content">
          <div class="btns">
            <el-button
              size="mini"
              type="primary"
              class="btn"
              @click="openDiaLog('补录行程')"
              >补录行程</el-button
            >
            <el-button
              size="mini"
              type="primary"
              class="btn"
              @click="openDiaLog('确认行程')"
              >确认行程</el-button
            >
          </div>

          <div class="search">
            <car-tree
              @getData="getVehIds2"
              :limit="1"
              ref="carTree"
              label="vehicleNo"
              size="mini"
            ></car-tree>
            <el-select
              v-model="moveType"
              size="mini"
              placeholder="请选择"
              class="self-select-width"
              @change="selectMoveType(2)"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-table
          class="basicBox dispatchMonitoring"
          :data="completedDetailList"
          @selection-change="getCompletedData"
          :height="heightBottom"
          stripe
          :cell-style="{ color: '#000', fontSize: '16px' }"
          :header-cell-style="{ background: '#FAFAFA', color: '#999999' }"
        >
          <el-table-column
            type="selection"
            width="55"
            :selectable="checkboxT"
            disabled="true"
          ></el-table-column>
          <el-table-column prop="cph" label="车辆">
            <template slot-scope="scope">
              <span class="column-color" @click="showBusPlan(scope.row)">{{
                scope.row.cph.slice(2)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="realityDriverName"
            label="驾驶员"
          ></el-table-column>
          <el-table-column prop="moveType" label="方向">
            <template slot-scope="scope">
              <span>{{ scope.row.moveType == 1 ? '上行' : '下行' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="planLeaveTime" label="计划发车">
            <template slot-scope="scope">
              {{ scope.row.planLeaveTime.slice(11, 16) }}
            </template></el-table-column
          >
          <el-table-column prop="leaveTime" label="实际发车">
            <template slot-scope="scope">
              {{ scope.row.leaveTime ? scope.row.leaveTime.slice(11, 16) : '' }}
            </template>
          </el-table-column>
          <el-table-column prop="planArriveTime" label="计划到达">
            <template slot-scope="scope">
              {{ scope.row.planArriveTime.slice(11, 16) }}
            </template></el-table-column
          >
          <el-table-column prop="arriveTime" label="实际到达">
            <template slot-scope="scope">
              {{
                scope.row.arriveTime ? scope.row.arriveTime.slice(11, 16) : ''
              }}
            </template>
          </el-table-column>
          <el-table-column prop="runMile" label="里程"></el-table-column>
          <el-table-column prop="natureState" label="状态">
            <template slot-scope="scope">
              {{ scope.row.natureState | filterNatureState }}
            </template>
          </el-table-column>
          <el-table-column label="详情">
            <template slot-scope="scope">
              <el-button
                v-if="$store.state.menu.nowMenuList.indexOf('查看') >= 0"
                type="text"
                :style="{ 'font-size': '16px' }"
                @click="openDiaLog('查看', scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      v-if="show"
      :title="title"
      :visible.sync="show"
      width="600px"
      append-to-body
      @close="closeDialog"
    >
      <div v-if="title == '查看'" class="table-box">
        <div class="refer-header">
          <label>里程数：{{ form.runMile }}</label>
          <label>趟次：{{ form.times }}</label>
        </div>
        <el-table
          stripe
          :data="referTable"
          :cell-style="{ color: '#000', fontSize: '16px' }"
          :header-cell-style="{ background: '#FAFAFA', color: '#999999' }"
          class="basicBox dispatchMonitoring"
        >
          <el-table-column prop="serialNo" label="序号"></el-table-column>
          <el-table-column
            prop="stationName"
            show-overflow-tooltip
            label="站点名称"
          ></el-table-column>
          <el-table-column prop="arriveTime" label="到达时间">
            <template slot-scope="scope">
              {{
                scope.row.arriveTime ? scope.row.arriveTime.slice(11, 16) : ''
              }}
            </template>
          </el-table-column>
          <el-table-column prop="leaveTime" label="发车时间">
            <template slot-scope="scope">
              {{ scope.row.leaveTime ? scope.row.leaveTime.slice(11, 16) : '' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="stopoverSecond"
            label="停留时间"
          ></el-table-column>
          ></el-table
        >
      </div>
      <div v-if="title == '补录行程'">
        <addRecordTripDialog
          :busLineList="lineOptions"
          :drivingStateList="drivingStateOptions"
          @closeDia="closeDialog"
          @submitTripDiaFrom="submitTripRecord"
        ></addRecordTripDialog>
      </div>
      <div v-if="title == '确认行程'">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          status-icon
          label-position="left"
          label-width="90px"
          class="demo-form form-search"
        >
          <el-form-item label="是否完成:" prop="drivingState">
            <el-radio-group v-model="form.drivingState">
              <el-radio
                v-for="item in drivingStateOptions"
                :key="item.value"
                :label="item.value"
                >{{ item.content }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="司机:">
            <el-select
              ref="select_element"
              v-model="form.realityDriver"
              clearable
              filterable
              :filter-method="vehicleMethodFun"
              placeholder="请输入司机"
            >
              <el-option
                v-for="item in driversOptions"
                :key="item.credentialsNo"
                :label="item.driverName"
                :value="item.credentialsNo"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="里程数:"
            prop="runMile"
            v-if="form.recordIds.length === 1 && form.drivingState === 1"
          >
            <el-input-number
              :min="0"
              size="small"
              v-model="form.runMile"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="备注:" prop="remark">
            <el-input
              style="margin-top: 10px; width: 250px"
              type="textarea"
              :rows="3"
              v-model="form.remark"
            ></el-input>
          </el-form-item>
          <div class="buttonGroup text-right">
            <el-button size="small" type="default" @click="closeDialog"
              >取 消</el-button
            >
            <el-button size="small" type="primary" @click="confirmSchedule"
              >确 定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryBusPlan,
  getDriverRecordList,
  queryPlanDetailList,
  queryRecordArriveList,
  confirmDrivingRecord,
  addDriverRecord,
  queryAllLineList,
  getDriverByline,
  getNotStartDispatchCheCiAPI
} from "@/api/lib/bus-api.js";
import carTree from "@/components/carTree/carSelectTree.vue";
import addRecordTripDialog from "../../../statisticalReports/dialogComponents/addRecordTripDialog";
import moment from "moment";
export default {
  props: {
    lineCode: {
      type: Number,
      default: null
    }
  },
  components: {
    carTree,
    addRecordTripDialog
  },
  data () {
    return {
      startPlanDetailList: [], //查询待发车列表
      completedDetailList: [], //查询已完成列表
      timer_startplan: null,
      completed_timer: null,
      active: "dfc",
      vehicleNo: "",
      moveType: null,
      options: [
        {
          label: "全部",
          value: null
        },
        {
          label: "上行",
          value: 1
        },
        {
          label: "下行",
          value: 2
        }
      ],
      title: "",
      show: false,
      referTable: [],
      form: {},
      list: [],
      drivingStateOptions: [
        { content: "完成", value: 1 },
        { content: "未完成", value: 2 }
      ],
      rules: {
        recordIds: [{ required: true, message: "不能为空", trigger: "change" }],
        runMile: [{ required: true, message: "不能为空", trigger: "change" }],
        drivingState: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        realityDriver: [
          { required: true, message: "不能为空", trigger: "change" }
        ]
        // isCountTimes: [
        //   { required: true, message: "不能为空", trigger: "change" }
        // ]
      },
      lineOptions: [],
      driversOptions: [],
      heightBottom: "calc(100vh - 42vh - 270px)",//calc(100vh - 47vh - 267px)
      buttonFlag: false,//是否展开
    };
  },
  watch: {
    lineCode (n, o) {
      //清空车辆过滤框值
      this.clearInputValue();
      this.getqueryStartPlanDetailList(); //查询待发车列表-定时请求
      this.getqueryCompletedDetailList(); //查询已完成列表
    }
  },
  filters: {
    filterNatureState (item) {
      if (item == 3) {
        return "加油";
      } else if (item == 2) {
        return "交班";
      } else if (item == 1) {
        return "排班";
      } else if (item == 4) {
        return "加气";
      } else if (item == 5) {
        return "充电";
      } else if (item == 6) {
        return "维修";
      } else if (item == 7) {
        return "包车";
      } else if (item == 128) {
        return "其他";
      } else if (item == 8) {
        return "恢复运营";
      } else {
        return "正常";
      }
    },
    filterBusState (status) {
      let str = "";
      if (status == 0) {
        str = "未收到";
      } else if (status == 1) {
        str = "已收到";
      } else if (status == 2) {
        str = "已发车";
      } else if (status == 3) {
        str = "已完成";
      } else if (status == 4) {
        str = "正常";
      }
      return str
    },
    filterAdjustStatusState (status) {
      let str = "";
      if (status == 0) {
        str = "删除";
      } else if (status == 1) {
        str = "正常";
      } else if (status == 2) {
        str = "正常";
      }
      return str
    },

  },
  computed: {
    TabBtnStyle: function () {
      if (!this.buttonFlag) {
        return 'top:0'
      } else {
        return `top: calc(-44vh - 26px)`
      }
    },
    TabStyle: function () {
      return `bottom:${this.buttonFlag ? 'calc( 44vh + 16px + 10px )' : 0};position: relative;` // tab的总高度
    },
  },
  mounted () {
    this.getLineList();
  },
  methods: {
    busStatusColor (status) {
      let colorValue = "#000";
      if (status == 1) {
        colorValue = "#d79b1b";
      }
      if (status == 0) {
        colorValue = "#fa4b1b";
      }
      return colorValue
    },
    /** 缩小 */
    narrow () {
      this.buttonFlag = !this.buttonFlag;
      this.heightBottom = "calc(100vh - 42vh - 270px)";
    },
    /** 放大 */
    enlarge () {
      this.buttonFlag = !this.buttonFlag;
      this.heightBottom = "calc(100vh - 42vh - 270px + 44vh + 16px + 10px)";
    },
    clearInputValue () {
      this.vehicleNo = "";
      this.$refs.carTreeWait?.resetForm();
      this.$refs.carTree?.resetForm();
    },
    //清空用户选择数据
    tablePlanSelectClear () {
      this.$refs.tablePlan.clearSelection();
    },
    //模糊查询司机
    vehicleMethodFun (text) {
      getDriverByline({ keyWord: text }).then(res => {
        if (res.code == 1000) {
          this.driversOptions = res.data;
        }
        // else if(res.code===1100){
        //   this.$message.warning(res.msg)
        // }
      });
    },
    //搜索框查询全部可选线路
    getLineList () {
      queryAllLineList().then(res => {
        if (res.code === 1000) {
          if (res.data.length == 0) {
            this.$message.warning("还没有线路信息，请先添加线路信息");
          } else {
            this.lineOptions = res.data;
          }
        }
      });
    },
    computedBtnsWidth () {
      const width = this.$refs.btns.clientWidth;
      if (width) {
      }
    },
    handleClick () {
      this.moveType = null;
      this.vehicleNo = "";
      if (this.active == "dfc") this.getqueryStartPlanDetailList();
      else this.getqueryCompletedDetailList();
    },
    getVehIds (val) {
      this.vehicleNo = val;
      this.getqueryStartPlanDetailList();
    },
    getVehIds2 (val) {
      this.vehicleNo = val;
      this.getqueryCompletedDetailList();
    },
    selectMoveType (val) {
      if (val == 1) this.getqueryStartPlanDetailList();
      else this.getqueryCompletedDetailList();
    },
    openDiaLog (title, row) {
      this.title = title;
      if (title == "查看") {
        this.form.runMile = row.runMile;
        this.form.times = row.times;
        queryRecordArriveList({ recordId: row.id }).then(res => {
          if (res.code == "1000") {
            this.show = true;
            this.referTable = res.data || [];
          }
        });
      } else if (title == "补录行程") {
        this.form = {
          workDay: "",
          companyId: "",
          vehicleNo: "",
          realityDriver: "",
          leaveTime: "",
          arriveTime: "",
          drivingState: "",
          // times:'',
          natureState: "",
          remark: ""
        };
        this.show = true;
      } else {
        if (!this.list.length) {
          this.$message.warning("请选择需要确认的记录");
          return;
        }
        this.form = {
          recordIds: this.list,
          remark: null,
          drivingState: null,
          realityDriver: null,
          runMile: null
        };
        this.show = true;
      }
    },
    closeDialog () {
      this.form = {};
      this.show = false;
      this.driversOptions = [];
    },
    //补录行程
    submitTripRecord (val) {
      addDriverRecord(val).then(res => {
        if (res.code === 1000) {
          this.$message.success("操作成功");
          this.show = false;
          this.getqueryCompletedDetailList();
        }
      });
    },
    confirmSchedule () {
      const data = { ...this.form };
      if (this.form.recordIds.length !== 1 || this.form.drivingState === 2) {
        delete data.runMile;
      }
      if (!this.form.realityDriver) {
        delete data.realityDriver;
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          confirmDrivingRecord(data)
            .then(res => {
              this.$message.success("操作成功");
              this.closeDialog();
              this.getqueryCompletedDetailList();
              console.log("-------------------", data);
            })
            .catch(error => { });
        }
      });
    },
    sendMessage () {
      this.$emit("sendMessage");
    },
    sendPlanText () {
      this.$emit("sendPlanText");
    },
    changeDriver () {
      this.$emit("changeDriver", true);
    },
    changeStatusSelect () {
      this.$emit("changeStatusSelect");
    },
    manualStartTogether () {
      this.$emit("manualStartTogether");
    },
    addShiftOrTripDia () {
      this.$emit("addShiftOrTripDia");
    },
    changeAverage(){
      this.$emit("changeAverage");
    },
    //禁用计划状态为删除的复选框
    checkboxT (row, index) {
      if (row.adjustStatus === "删除") {
        return false;
      } else {
        return true;
      }
    },
    //选中数据处理
    getCheckedData (val) {
      this.$emit("getCheckedData", val);
    },
    //选择已完成的数据
    getCompletedData (val) {
      this.list = val.map(i => i.id);
      console.log(this.list);
    },
    //查看车辆计划
    showBusPlan (row) {
      const data = {
        vehicleNo: row.vehicleNo,
        planType: 2,
        workDay: moment(new Date()).format("YYYY-MM-DD")
      };
      let busPlanList = [];
      queryBusPlan(data)
        .then(res => {
          res.data.planList.forEach(item => {
            if (!(item.down == "" && item.up == "")) {
              busPlanList.push(item);
            }
          });
          this.$emit(
            "showBusPlan",
            busPlanList,
            res.data.cph,
            res.data.banCiMoveType
          );
        })
        .catch(err => {
          console.log(err);
        });
    },
    //查询待发车列表
    getqueryStartPlanDetailList () {
      if (this.timer_startplan) clearInterval(this.timer_startplan);
      const intervalIimerFn = () => {
        // queryPlanDetailList({
        //   companyId: this.lineCode,
        //   vehicleNo: this.vehicleNo,
        //   moveType: this.moveType
        // })
        //   .then(res => {
        //     this.startPlanDetailList = res.data || [];
        //   })
        //   .catch(err => {
        //     console.log(err);
        //   });
        getNotStartDispatchCheCiAPI({
          companyId: this.lineCode,
          vehicleNo: this.vehicleNo,
          moveType: this.moveType,
          workTime: moment(new Date()).format("YYYY-MM-DD"),
          isArranging: 0
        }).then(res => {
          this.startPlanDetailList = res.data || [];
        }).catch(err => {
          console.log(err);
        });
      };
      intervalIimerFn();
      this.timer_startplan = setInterval(() => {
        intervalIimerFn();
      }, 30000);
    },
    //查询已完成列表
    getqueryCompletedDetailList () {
      if (this.completed_timer) clearInterval(this.completed_timer);
      const data = {
        companyIds: [this.lineCode],
        vehicleNoList: this.vehicleNo ? [this.vehicleNo] : [],
        moveType: this.moveType,
        currentPage: 1,
        pageSize: 9999,
        beginTime: moment(new Date()).format("YYYY-MM-DD"),
        endTime: moment(new Date()).format("YYYY-MM-DD"),
        drivingState: 1 //状态：已完成
      };
      const intervalIimerFn = () => {
        getDriverRecordList(data)
          .then(res => {
            this.completedDetailList = res.data.list || [];
          })
          .catch(err => {
            console.log(err);
          });
      };
      intervalIimerFn();
      this.completed_timer = setInterval(() => {
        intervalIimerFn();
      }, 30000);
    }
  },
  beforeDestroy () {
    clearInterval(this.timer_startplan);
    if (this.completed_timer) clearInterval(this.completed_timer);
    console.log("组件是否销毁");
  }
};
</script>
<style lang="scss" scoped>
.scheduling-bottom-table {
  position: relative;
  z-index: 1999;
  @include themify() {
    .hideBox {
      position: absolute;
      right: 0;
      z-index: 2000;
      width: 60px;
      span {
        width: 74px;
        height: 24px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-size: 5rem;
        background-position: center;
        background-repeat: no-repeat;
        color: themed('b4');
        border-color: themed('b3');
        border-radius: 6px 6px 0px 0px;
        cursor: pointer;
      }
    }
  }
  .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;
    .btns {
      flex: 1;
      .btn{
        padding: 7px 6px;
      }
    }
    .search {
      display: flex;
    }
  }
  .column-color {
    color: #f48b41;
    cursor: pointer;
  }
  .table-box {
    padding: 20px;
    box-sizing: border-box;
    .refer-header {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      padding-top: 0;
    }
  }
  /deep/.el-input--mini .el-input__inner {
    width: 140px;
  }
  .self-select-width {
    width: 71px;
    /deep/.el-input--mini .el-input__inner {
      width: 71px;
    }
  }
}
</style>
