<template>
  <el-select
    ref="select_element"
    v-model="value"
    :clearable="clearable"
    @clear="resetForm"
    :filterable="searchable"
    :filter-method="filterVeh"
    :disabled="isEdit"
    @change="handleSelect"
    placeholder="请输入"
    :loading="loading"
    :size="size"
    :popper-append-to-body="false"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.cph"
      :value="item[label]"
    ></el-option>
  </el-select>
</template>

<script>
/**
 * 选择车辆组件   模糊搜索 单选
 *
 */
import { queryVehicleFuzzy } from "@/api/lib/refreshAlarm.js";
export default {
  props: {
    label: {
      type: String,
      default: "id"
    },
    /* 已选中车牌号 */
    choosedCph: {
      type: String,
      default: ""
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    /**禁用 */
    isEdit: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    /* 多选 */
    multiple: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    searchable: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    /**车辆-历史视频专用 */
    isHistory: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    size: {
      type: String,
      default: "medium"
    }
  },
  created() {},

  data() {
    return {
      value: null, // 初始值
      options: [],
      loading: false
    };
  },

  methods: {
    /** 清空input */
    resetForm() {
      this.value = null;
      this.options = [];
    },

    /** 车队搜索方法 */
    filterVeh(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          let data = { cph: query, system: sessionStorage.getItem("system") };

          queryVehicleFuzzy(data).then(res => {
            if (res.code === 1000) {
              this.options = res.data;
            }
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    handleSelect() {
      let value = null;
      if (this.isHistory) {
        value = this.options.filter(res => res[this.label] == this.value);
      }
      this.$emit("getData", this.value, value);
    }
  },
  watch: {
    choosedCph(val, oldVal) {
      if (val) {
        let data = { cph: val, system: sessionStorage.getItem("system") };

        queryVehicleFuzzy(data).then(res => {
          if (res.code === 1000) {
            this.options = res.data;

            this.value = res.data[0][this.label];
            if (!this.isHistory) {
              this.$emit("getData", this.value);
            }
          }
        });
      }
    }
  }
};
</script>
