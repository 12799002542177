<template>
  <!-- 公交2.0 实时调度 -->
  <div class="RealTimeScheduling">
    <!-- 顶部搜索框（关注线路） -->
    <div class="top-search">
      <scheduling-header
        @queryCurrentLine="queryCurrentLine"
      ></scheduling-header>
    </div>
    <!-- 模拟地图 -->
    <scheduling-view
      :lineCode="lineCode"
      :activeName="activeName"
      @sendPlanText="sendPlanText"
      @showCarDetail="showCarDetail"
      @sendMessage="sendMessage"
      @changeDriverPop="changeDriverPop"
      @manualStart="manualStart"
      @changeStatus="changeStatus"
      ref="schedulingView"
    />
    <!-- 底部table -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <scheduling-bottom-table
          :lineCode="lineCode"
          @showBusPlan="showBusPlan"
          @getCheckedData="getCheckedData"
          @sendMessage="sendMessage"
          @sendPlanText="sendPlanText"
          @changeDriver="changeDriver"
          @changeStatusSelect="changeStatusSelect"
          @changeAverage="changeAverage"
          @manualStartTogether="manualStartTogether"
          @addShiftOrTripDia="() => (addShiftOrTripDia = true)"
          ref="schedulingBottomTable"
        />
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="grid-content">
          <el-tabs type="border-card">
            <el-tab-pane label="消息列表">
              <el-table
                class="basicBox dispatchMonitoring"
                :data="messageList"
                :height="heightBottom"
                stripe
                :cell-style="{ color: '#000', fontSize: '16px' }"
                :header-cell-style="{ background: '#FAFAFA', color: '#999999' }"
              >
                <el-table-column
                  type="index"
                  label="排序"
                  align="center"
                ></el-table-column>
                <el-table-column prop="cph" label="车辆">
                  <template slot-scope="scope">
                    <span style="color: #f48b41">{{
                      scope.row.cph.slice(2)
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="线路"
                  prop="companyName"
                ></el-table-column>
                <el-table-column
                  prop="content"
                  label="消息内容"
                  width="180"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column prop="type" label="消息类型"></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="时间"
                  width="180"
                ></el-table-column>
                <el-table-column prop="status" label="状态">
                  <template slot-scope="scope">
                    {{ scope.row.status | filterStatus }}
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="到离站信息">
              <el-table
                class="basicBox dispatchMonitoring"
                :data="arriveAndLeaveStationList"
                :height="heightBottom"
                stripe
                :cell-style="{ color: '#000', fontSize: '16px' }"
                :header-cell-style="{ background: '#FAFAFA', color: '#999999' }"
              >
                <el-table-column
                  type="index"
                  label="排序"
                  align="center"
                ></el-table-column>
                <el-table-column prop="cph" label="车辆">
                  <template slot-scope="scope">
                    <span style="color: #f48b41">{{
                      scope.row.cph.slice(2)
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="线路"
                  prop="companyName"
                ></el-table-column>
                <el-table-column prop="businessType" label="方向">
                  <template slot-scope="scope">
                    {{ scope.row.businessType === 1 ? '上行' : '下行' }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop="gpsTime"
                  label="时间"
                  width="180"
                ></el-table-column>
                <el-table-column prop="arriveType" label="类型">
                  <template slot-scope="scope">
                    {{ scope.row.arriveType === 1 ? '到站' : '离站' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="stationName"
                  label="站点名称"
                  width="160"
                  :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                  prop="realityDriverName"
                  label="当班司机"
                ></el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"
                >车辆请求(<span
                  :style="{ color: requestlistNeedComprate > 0 ? 'red' : '' }"
                  >{{ requestlistNeedComprate }}</span
                >)</span
              >
              <el-table
                class="basicBox dispatchMonitoring"
                :data="requestlist"
                :height="heightBottom"
                stripe
                :cell-style="{ color: '#000', fontSize: '16px' }"
                :header-cell-style="{ background: '#FAFAFA', color: '#999999' }"
              >
                <!-- <el-table-column
                  type="index"
                  label="排序"
                  align="center"
                ></el-table-column> -->
                <el-table-column prop="cph" label="车辆" width="100">
                  <template slot-scope="scope">
                    <div class="flex-style">
                      <i :class="getBusIcon(scope.row.isOnline)"></i>
                      <span>{{ scope.row.cph.slice(2) }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="线路"
                  prop="companyName"
                ></el-table-column>
                <el-table-column
                  prop="driverName"
                  label="司机"
                ></el-table-column>
                <el-table-column prop="eventId" label="消息内容" width="120">
                  <template slot-scope="scope">{{
                    (
                      natureOptions.find(
                        (t) => t.dictCode == scope.row.eventId
                      ) || { dictValue: '其他' }
                    ).dictValue
                  }}</template>
                </el-table-column>
                <el-table-column
                  prop="insertTime"
                  label="时间"
                  width="180"
                ></el-table-column>
                <el-table-column prop="opStats" label="状态" width="120">
                  <template slot-scope="scope">
                    <span
                      :style="{
                        color: scope.row.opStats == 2 ? '#f74242' : '',
                      }"
                    >
                      {{ scope.row.opStats | filteropStats }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column prop="action" label="操作" width="100">
                  <template slot-scope="scope">
                    <div class="action">
                      <span
                        @click="agreeAndReject(scope.row.id, 1)"
                        class="agree"
                        v-if="
                          scope.row.opStats !== 1 && scope.row.opStats !== 2
                        "
                        >同意</span
                      >
                      <span
                        @click="agreeAndReject(scope.row.id, 2)"
                        class="reject"
                        v-if="
                          scope.row.opStats !== 1 && scope.row.opStats !== 2
                        "
                        >拒绝</span
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
    <!-- 弹框 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="50%"
    >
      <sendMsg
        :lineId="lineCode"
        @closeDia="() => (dialogVisible = false)"
      ></sendMsg>
    </el-dialog>
    <!-- 调整计划 -->
    <el-dialog
      title="计划调整"
      :visible.sync="driverdialogVisible"
      v-if="driverdialogVisible"
      width="50%"
    >
      <driverPlan
        :updateform="updateform"
        :nowBusByline="nowBusByline"
        :nowDriverByline="nowDriverByline"
        :isPlan="isPlan"
        @closeDia="closeDiaChange"
      ></driverPlan>
    </el-dialog>
    <!-- 查看发车计划 -->
    <el-dialog
      title="单车发班表"
      :visible.sync="schedulingVisible"
      v-if="schedulingVisible"
      width="30%"
    >
      <schedulingPlan
        :busPlanList="busPlanList"
        :busCode="busCode"
        :banCiMoveType="banCiMoveType"
        @closeDia="() => (schedulingVisible = false)"
      ></schedulingPlan>
    </el-dialog>
    <!-- 添加班次/车次 -->
    <el-dialog
      title="添加班次/车次"
      :visible.sync="addShiftOrTripDia"
      v-if="addShiftOrTripDia"
      width="50%"
      class="addShiftOrTripDia"
      @close="cancleAddShiftOrTripDia"
      @open="addShiftOrTripDia = true"
    >
      <addShiftOrTrip
        ref="addShiftOrTripRefs"
        @closeDia="() => (addShiftOrTripDia = false)"
      ></addShiftOrTrip>
    </el-dialog>
    <!-- 修改车辆状态 -->
    <el-dialog
      title="修改车辆状态"
      :visible.sync="changeStatusVisible"
      v-if="changeStatusVisible"
      width="50%"
      class="addShiftOrTripDia"
    >
      <el-form
        :model="statusForm"
        ref="statusForm"
        label-width="102px"
        :rules="rules"
        class="demo-form dialog-form"
      >
        <el-form-item label="车次状态：" prop="state">
          <el-select v-model="statusForm.state" placeholder="选择车次状态">
            <el-option
              v-for="item in natureOptions"
              :label="item.dictValue"
              :value="Number(item.dictCode)"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          size="small"
          @click="changeStatusVisible = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="saveStatus"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 车辆详情面板 -->
    <car-dialog
      v-if="dialogTableVisible"
      :titlex="plate"
      :id="plate"
      :area="area"
      :street="street"
      :checkObj="carDetailObj"
      :Upgrades="true"
      @dialogVisible="() => (dialogTableVisible = false)"
    ></car-dialog>
    <!-- 匀点 -->
    <el-dialog
      title="排班匀点"
      :visible.sync="averagdialogVisible"
      v-if="averagdialogVisible"
      width="50%"
    >
      <div class="form search-area">
        <el-form
          :model="averageform"
          status-icon
          :rules="averagerules"
          label-position="left"
          ref="averageform"
          label-width="102px"
          class="demo-form dialog-form"
        >
          <el-form-item label="匀点方向" prop="direction" style="width: 400px">
            <el-select v-model="averageform.direction" placeholder="请选择方向">
              <el-option label="向前" value="1"></el-option>
              <el-option label="向后" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="匀点数" prop="spotCount" style="width: 400px">
            <el-input v-model="averageform.spotCount"></el-input>
            <span class="numberNotice"
              >操作说明：匀点车次相邻车次的匀点数为1，以此类推匀点数递增</span
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="buttonGroup text-right">
        <el-button
          size="small"
          type="default"
          @click="averagdialogVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveaverage"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  sendMessageList,
  queryNewEventsList,
  queryArriveLeaveStationList,
  queryDispatchStatisticsAll,
  sendPlanText,
  manualSendVehicle,
  manualModifyEvent,
  busDetailInfo,
  queryBusCardAPI,
  queryNewEventsAPI,
  averageSpot
} from "@/api/lib/bus-api.js";
import { queryDictsByCodes } from "@/api/lib/api.js";
import { updateEventReport } from "@/api/lib/refreshAlarm.js";
import sendMsg from "./components/sendMessage";
import driverPlan from "./components/driverPlan";
import schedulingPlan from "./components/schedulingPlan";
import addShiftOrTrip from "./components/addShiftOrTrip";
import dialog from "./components/dialog";
import schedulingHeader from "./components/schedulingHeader.vue";
import moment from "moment";
import SchedulingView from "./components/schedulingView.vue";
import SchedulingBottomTable from "./components/schedulingBottomTable.vue";
export default {
  components: {
    sendMsg,
    driverPlan,
    schedulingPlan,
    addShiftOrTrip,
    schedulingHeader,
    "car-dialog": dialog,
    SchedulingView,
    SchedulingBottomTable
  },
  data () {
    return {
      dialogTableVisible: false, //显示车辆详情
      carDetailObj: {},
      area: "",
      street: "",
      color: "#66B1FF",
      plate: "", //当前选中车辆

      system: sessionStorage.getItem("system"),
      currentPage: 1,
      pageSize: 20,
      activeName: "",
      lineCode: 0,
      // lineOptions: [],

      messageList: [], //消息列表
      requestlist: [], //车辆请求列表
      requestlistNeedComprate: 0,//车辆请求数据含需要处理的请求
      arriveAndLeaveStationList: [], //到离站信息列表
      dispatchPlanList: [], //查询主副场即将发车的计划列表

      busPlanList: [], //车辆发车计划
      busCode: "",
      banCiMoveType: 0,
      nowBusByline: [],
      nowDriverByline: [],

      natureOptions: [],

      dialogVisible: false, //下发消息
      driverdialogVisible: false, //调整计划
      schedulingVisible: false, //发车计划
      addShiftOrTripDia: false, //添加班次车次
      changeStatusVisible: false, //修改车次状态
      statusForm: {
        state: ""
      },
      title: "",
      changeStatusVehicleNo: "", //修改状态选中的车架号
      updateform: {
        lineId: null,
        oldCredentialsNo: null,
        oldVehicleNo: null,
        newCredentialsNo: null,
        newVehicleNo: null,
        workDay: null,
        classType: "1",
        updateType: null,
        type: "1",
        beginTime: null,
        natureState: null,
        remark: null,
        cheCiIds: [],
        moveType: ""
      },
      rules: {
        state: [{ required: true, message: "不能为空", trigger: "change" }]
      },

      timer_msg: null,
      timer_startplan: null,
      timer_arriveAndLeave: null,
      timer_dispath: null,
      timer_event: null,
      checkboxFlag: false,
      heightBottom: "calc(100vh - 42vh - 238px)",//calc(100vh - 47vh - 233px)
      averagdialogVisible: false,//匀点
      averageform: {
        direction: "2",
        cheCiIdSelected: null,
        spotCount: null
      },
      averagerules: {
        spotCount: [
          { required: true, message: "不能为空", trigger: "change" },
          {
            validator (rule, value, callback) {
              if (/(^[1-9]\d*$)/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入正整数"));
              }
            },
            trigger: "blur"
          }
        ],
        cheCiIdSelected: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        direction: [{ required: true, message: "不能为空", trigger: "change" }]
      },
      isPlan:false,
    };
  },
  filters: {
    filterStatus (val) {
      let data = "";
      if (val === 0) {
        data = "默认";
      } else if (val === 1) {
        data = "失败";
      } else if (val === 2) {
        data = "成功";
      } else {
        data = val;
      }
      return data;
    },
    filteropStats (item) {
      if (item == 0) {
        return "未处理";
      } else if (item == 1) {
        return "同意";
      } else if (item == 2) {
        return "拒绝";
      } else if (item == 3) {
        return "未处理(已阅)";
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.timer_msg);
    clearInterval(this.timer_event);

    clearInterval(this.timer_startplan);
    clearInterval(this.timer_arriveAndLeave);
    clearInterval(this.timer_dispath);
  },

  watch: {
    lineCode (n, o) {
      this.getqueryArriveLeaveStationList(); //到离站信息列表-定时请求
      this.getDispatchStatisticsAll(); //车辆调度统计-定时请求
      this.getSendMessageList(); //消息列表-定时请求--不跟随线路改变而改变---公交2.0产品更改为跟随线路变化
      this.getqueryNewEventsList(); //车辆请求-定时请求--不跟随线路改变而改变
    }
  },
  mounted () {
    this.getDicts(); //字典
  },
  methods: {
    /**
     * 2.0迭代
     */
    queryCurrentLine (obj) {
      this.lineCode = obj.companyId;
      this.activeName = obj.companyName;
    },
    //查看车辆计划
    showBusPlan (list, busCode, banCiMoveType) {
      this.busCode = busCode;
      this.banCiMoveType = banCiMoveType;
      this.busPlanList = list;
      this.schedulingVisible = true;
    },

    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: "BUSLX" }).then(res => {
        if (res.code === 1000) {
          this.natureOptions = res.data.BUSLX;
        }
      });
    },
    //车辆状态图标
    getBusIcon (status) {
      if (status) {
        return "bus-norm bus-icon";
      } else {
        return "bus-offline bus-icon";
      }
    },
    //车辆请求
    getqueryNewEventsList () {
      if (this.timer_event) clearInterval(this.timer_event);
      const intervalIimerFn = () => {
        // queryNewEventsList
        queryNewEventsAPI()
          .then(res => {
            this.requestlist = res.data || [];
            let num = 0;
            //计算当前数据的
            this.requestlist.forEach(item => {
              if (item.opStats !== 1 && item.opStats !== 2) num++;
            })
            this.requestlistNeedComprate = num;
          })
          .catch(err => {
            console.log(err);
          });
      };
      intervalIimerFn();
      this.timer_event = setInterval(() => {
        intervalIimerFn();
      }, 30000);
    },
    //车辆请求同意和拒绝操作
    agreeAndReject (id, opStats) {
      updateEventReport({ id, opStats })
        .then(res => {
          if (res.code === 1000) {
            this.$message.success("修改成功");
            this.getqueryNewEventsList();
          } else {
            this.$message.warning(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    changeDriverPop (val) {
      //清空底部table用户选中数据
      this.$refs.schedulingBottomTable.tablePlanSelectClear();
      this.updateform.oldCredentialsNo = val.credentialsNo;
      this.updateform.oldVehicleNo = val.vehicleNo;
      this.updateform.beginTime = val.beginTime;
      this.nowBusByline = [{ cph: val.cph, vehicleNo: val.vehicleNo }];
      this.nowDriverByline = [
        { driverName: val.realityDriverName, credentialsNo: val.credentialsNo }
      ];
      this.updateform.cheCiIds = [val.id];
      //从这里点的调整计划都是修改未来的
      this.changeDriver(null,'isPlan');
    },
    getCheckedData (val) {
      if (val.length == 1) {
        this.changeStatusVehicleNo = val[0].vehicleNo;
        this.updateform.oldCredentialsNo = val[0].credentialsNo;
        this.updateform.oldVehicleNo = val[0].vehicleNo;
        this.updateform.beginTime = val[0].beginTime;
        this.updateform.moveType = val[0].moveType;
        this.nowBusByline = [{ cph: val[0].cph, vehicleNo: val[0].vehicleNo }];
        this.nowDriverByline = [
          { driverName: val[0].driverName || val[0].realityDriverName, credentialsNo: val[0].credentialsNo }
        ];
      }
      this.updateform.cheCiIds = val.map(ele => ele.id);
      this.checkboxFlag = true;//是底部table触发的多选数据
    },
    //修改状态
    changeStatus (item) {
      this.changeStatusVehicleNo = item.vehicleNo;
      this.changeStatusVisible = true;
    },
    changeStatusSelect () {
      if (this.updateform.cheCiIds.length == 1) {
        this.changeStatusVisible = true;
      } else {
        this.$message.warning("请勾选1个点进行修改状态！");
      }
    },
    //保存修改状态
    saveStatus () {
      this.$refs.statusForm.validate(valid => {
        if (valid) {
          const data = {
            eventId: this.statusForm.state,
            vehicleNo: this.changeStatusVehicleNo
          };
          manualModifyEvent(data)
            .then(res => {
              if (res.code === 1000) {
                this.changeStatusVisible = false;
                this.$message.success(res.msg);
                //刷新页面
                this.$refs.schedulingView.refreshView();
              } else {
                this.$message.warning(res.msg);
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
    },
    // 打开匀点弹窗
    changeAverage () {
      if (this.updateform.cheCiIds.length == 1) {
        this.averagdialogVisible = true;
        this.averageform.cheCiIdSelected = this.updateform.cheCiIds[0];
      } else {
        this.$message.warning("请勾选1个点进行匀点！");
      }
    },
    // 保存匀点
    saveaverage () {
      this.$refs.averageform.validate(valid => {
        if (valid) {
          let data = { ... this.averageform };
          data.moveType = this.updateform.moveType;//上下行
          averageSpot(data)
            .then(res => {
              if (res.code === 1000) {
                this.$message.success("操作成功");
                this.averagdialogVisible = false;
                //刷新页面
                this.$refs.schedulingView.refreshView();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
    },
    //手动发车
    manualStart (item) {
      const h = this.$createElement;
      this.$msgbox({
        title: "手动发车",
        type: "warning",
        message: h("p", null, [h("span", null, "确定手动发车吗 ？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          manualSendVehicle({
            vehicleNo: item.vehicleNo,
            moveType: item.moveType
          }).then(res => {
            if (res.code === 1000) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消手动发车"
          });
        });
    },
    manualStartTogether () {
      if (this.updateform.cheCiIds.length == 1) {
        const data = {
          vehicleNo: this.updateform.oldVehicleNo,
          moveType: this.updateform.moveType
        };
        const h = this.$createElement;
        this.$msgbox({
          title: "手动发车",
          type: "warning",
          message: h("p", null, [h("span", null, "确定手动发车吗 ？")]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        })
          .then(() => {
            manualSendVehicle(data).then(res => {
              if (res.code === 1000) {
                this.$message.success(res.msg);
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              showClose: true,
              message: "已取消手动发车"
            });
          });
      } else {
        this.$message.warning("请勾选1个点手动发车！");
      }
    },
    //关闭计划弹窗
    cancleAddShiftOrTripDia () {
      this.$refs.addShiftOrTripRefs.cancleAddShiftOrTripDia();
    },
    closeDiaChange () {
      this.driverdialogVisible = false;
    },
    // 打开调整计划弹窗
    changeDriver (val,isPlan) {
      if (val && !this.checkboxFlag) {
        this.$message.warning("请至少勾选1个点进行调整计划！");
        return;
      } else {
        if (!this.updateform.cheCiIds.length) {
          //是底部table触发的多选数据
          this.$message.warning("请至少勾选1个点进行调整计划！");
          return;
        }
      }
      this.isPlan = isPlan==='isPlan';//是否是修改未来计划
      if (this.updateform.cheCiIds.length > 1) {
        this.updateform.type = "3";
      } else if (this.updateform.cheCiIds.length === 1) {
        this.updateform.type = "1";
      }
      console.log("-------", this.updateform.oldVehicleNo);
      this.updateform.lineId = this.editableTabsValue * 1;
      this.updateform.updateType = 0;
      this.updateform.newCredentialsNo = null;
      this.updateform.newVehicleNo = null;
      this.driverdialogVisible = true;
    },
    //下发行车计划
    sendPlanText (id) {
      if (this.updateform.cheCiIds.length > 0 || id) {
        this.$confirm("车辆将收到最新的行车计划，是否下发?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            sendPlanText({
              checiIds: id ? [id] : this.updateform.cheCiIds
            }).then(res => {
              if (res.code === 1000) {
                this.$message.success("操作成功");
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消下发行车计划"
            });
          });
      } else {
        this.$message.warning("请至少勾选1个点进行下发行车计划！");
      }
    },
    //发送消息
    sendMessage () {
      this.dialogVisible = true;
      this.title = "发送消息";
    },
    //消息列表
    getSendMessageList () {
      if (this.timer_msg) clearInterval(this.timer_msg);
      const intervalIimerFn = () => {
        const data = {
          system: this.system,
          pageSize: this.pageSize,
          currentPage: this.currentPage,
          companyIds: [this.lineCode]
        };
        sendMessageList(data)
          .then(res => {
            this.messageList = res.data.list || [];
          })
          .catch(err => {
            console.log(err);
          });
      };
      intervalIimerFn();
      this.timer_msg = setInterval(() => {
        intervalIimerFn();
      }, 30000);
    },

    // //切换线路
    // changeLine (val) {
    //   let obj =null;
    //   obj = this.lineOptions.filter(item=>item.companyId === val)[0]
    //   this.activeName = obj.companyName
    //   //刷新下面的模拟地图数据
    // },

    //到离站信息列表
    getqueryArriveLeaveStationList () {
      if (this.timer_arriveAndLeave) clearInterval(this.timer_arriveAndLeave);
      const intervalIimerFn = () => {
        queryArriveLeaveStationList({ companyId: this.lineCode })
          .then(res => {
            this.arriveAndLeaveStationList = res.data || [];
          })
          .catch(err => {
            console.log(err);
          });
      };
      intervalIimerFn();
      this.timer_arriveAndLeave = setInterval(() => {
        intervalIimerFn();
      }, 30000);
    },
    //车辆调度统计
    getDispatchStatisticsAll () {
      if (this.timer_dispath) clearInterval(this.timer_dispath);
      const intervalIimerFn = () => {
        queryDispatchStatisticsAll({ companyId: this.lineCode })
          .then(res => {
            if (res.code === 1000) {
              this.dispatchStatisticsData = res.data;
            } else {
              this.dispatchStatisticsData = {
                online: 0,
                operate: 0,
                matching: 0,
                notOperateList: []
              };
            }
          })
          .catch(err => {
            console.log(err);
          });
      };
      intervalIimerFn();
      this.timer_dispath = setInterval(() => {
        intervalIimerFn();
      }, 20000);
    },

    // // 点击车牌号显示弹窗
    // onCarClick(event) {
    //   let dom = event.target;
    //   if (dom.getAttribute("vehicleNo"))
    //     this.showCarDetail(dom.getAttribute("vehicleNo"));
    // },
    // 点击车牌号显示弹窗
    showCarDetail (vehicleNo) {
      queryBusCardAPI({ vehicleNo: vehicleNo, companyId: this.lineCode }).then(res => {
        if (res.data) {
          this.plate = res.data.cph;
          this.carDetailObj = res.data;
          this.dialogTableVisible = true;
        }
      });
      this.dialogTableVisible = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.theme-project-bus {
  .RealTimeScheduling {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
    font-size: 14px;
  }
}
.inner-box {
  text-align: center;
  .inner-item {
    line-height: 28px;
    cursor: pointer;
  }
}

// /deep/.el-table {
//   td,
//   th {
//     font-size: 12px !important;
//   }
//   .cell {
//     line-height: 36px;
//   }
// }
/deep/.el-main {
  padding: 10px;
}
/deep/.el-table:not(.no-blank) .el-table__empty-text {
  height: 0;
}
/deep/.el-tabs--border-card > .el-tabs__content {
  padding: 0;
}
.RealTimeScheduling {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
  //车辆请求样式

  .grid-content {
    .top-content {
      .btn {
        background: #ebedf0;
        line-height: 24px;
        padding: 0 6px;
      }
    }
    .basicBox {
      margin-top: 10px;
    }
    .action {
      // display: flex;
      .agree {
        cursor: pointer;
        color: #336ffe;
      }
      .reject {
        cursor: pointer;
        color: #f74242;
        margin-left: 10px;
      }
    }
  }
}
.bus-icon {
  display: inline-block;
  content: '';
  height: 20px;
  width: 20px;
}
.bus-norm {
  background: url('../../../../assets/images/busIcon/bus_map_nor.png') no-repeat;
  background-size: 100%;
}

.bus-offline {
  background: url('../../../../assets/images/busIcon/bus_map_leave.png')
    no-repeat;
  background-size: 100%;
}
.flex-style {
  display: flex;
  align-items: center;
}
</style>
