var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scheduling-bottom-table" },
    [
      _c("div", { staticClass: "hideBox", style: _vm.TabBtnStyle }, [
        _vm.buttonFlag
          ? _c("span", { staticClass: "narrow", on: { click: _vm.narrow } }, [
              _c("i", { staticClass: "iconfont its_zhankai" })
            ])
          : _vm._e(),
        !_vm.buttonFlag
          ? _c("span", { staticClass: "enlarge", on: { click: _vm.enlarge } }, [
              _c("i", { staticClass: "iconfont its_zhedie" })
            ])
          : _vm._e()
      ]),
      _c(
        "el-tabs",
        {
          style: _vm.TabStyle,
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "dfc", label: "未完成" } },
            [
              _c("div", { staticClass: "top-content" }, [
                _c(
                  "div",
                  { ref: "btns", staticClass: "btns" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.sendMessage }
                      },
                      [_vm._v("消息下发")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.sendPlanText }
                      },
                      [_vm._v("下发计划")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.changeDriver }
                      },
                      [_vm._v("调整计划")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.addShiftOrTripDia }
                      },
                      [_vm._v("增加计划")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.manualStartTogether }
                      },
                      [_vm._v("手动发车")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.changeStatusSelect }
                      },
                      [_vm._v("修改状态")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.changeAverage }
                      },
                      [_vm._v("匀点")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "search" },
                  [
                    _c("car-tree", {
                      ref: "carTreeWait",
                      attrs: { limit: 1, label: "vehicleNo", size: "mini" },
                      on: { getData: _vm.getVehIds }
                    }),
                    _c(
                      "el-select",
                      {
                        staticClass: "self-select-width",
                        attrs: { size: "mini", placeholder: "请选择" },
                        on: {
                          change: function($event) {
                            return _vm.selectMoveType(1)
                          }
                        },
                        model: {
                          value: _vm.moveType,
                          callback: function($$v) {
                            _vm.moveType = $$v
                          },
                          expression: "moveType"
                        }
                      },
                      _vm._l(_vm.options, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "el-table",
                {
                  ref: "tablePlan",
                  staticClass: "basicBox dispatchMonitoring",
                  attrs: {
                    data: _vm.startPlanDetailList,
                    height: _vm.heightBottom,
                    stripe: "",
                    "cell-style": { color: "#000", fontSize: "16px" },
                    "header-cell-style": {
                      background: "#FAFAFA",
                      color: "#999999"
                    }
                  },
                  on: { "selection-change": _vm.getCheckedData }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      selectable: _vm.checkboxT,
                      disabled: "true"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "cph", label: "车辆" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "column-color",
                                on: {
                                  click: function($event) {
                                    return _vm.showBusPlan(scope.row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(scope.row.cph.slice(2)))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "driverName", label: "驾驶员" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "moveType", label: "方向" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.moveType == 1 ? "上行" : "下行"
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "beginTime", label: "计划发车" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "endTime", label: "计划到达" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "busStatus", label: "行车状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                style: {
                                  color: _vm.busStatusColor(scope.row.busStatus)
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterBusState")(
                                      scope.row.busStatus
                                    )
                                  )
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "adjustStatus", label: "计划状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("filterAdjustStatusState")(
                                    scope.row.adjustStatus
                                  )
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "ywc", label: "已完成" } },
            [
              _c("div", { staticClass: "top-content" }, [
                _c(
                  "div",
                  { staticClass: "btns" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.openDiaLog("补录行程")
                          }
                        }
                      },
                      [_vm._v("补录行程")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.openDiaLog("确认行程")
                          }
                        }
                      },
                      [_vm._v("确认行程")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "search" },
                  [
                    _c("car-tree", {
                      ref: "carTree",
                      attrs: { limit: 1, label: "vehicleNo", size: "mini" },
                      on: { getData: _vm.getVehIds2 }
                    }),
                    _c(
                      "el-select",
                      {
                        staticClass: "self-select-width",
                        attrs: { size: "mini", placeholder: "请选择" },
                        on: {
                          change: function($event) {
                            return _vm.selectMoveType(2)
                          }
                        },
                        model: {
                          value: _vm.moveType,
                          callback: function($$v) {
                            _vm.moveType = $$v
                          },
                          expression: "moveType"
                        }
                      },
                      _vm._l(_vm.options, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "el-table",
                {
                  staticClass: "basicBox dispatchMonitoring",
                  attrs: {
                    data: _vm.completedDetailList,
                    height: _vm.heightBottom,
                    stripe: "",
                    "cell-style": { color: "#000", fontSize: "16px" },
                    "header-cell-style": {
                      background: "#FAFAFA",
                      color: "#999999"
                    }
                  },
                  on: { "selection-change": _vm.getCompletedData }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      selectable: _vm.checkboxT,
                      disabled: "true"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "cph", label: "车辆" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "column-color",
                                on: {
                                  click: function($event) {
                                    return _vm.showBusPlan(scope.row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(scope.row.cph.slice(2)))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "realityDriverName", label: "驾驶员" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "moveType", label: "方向" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.moveType == 1 ? "上行" : "下行"
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "planLeaveTime", label: "计划发车" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.planLeaveTime.slice(11, 16)) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "leaveTime", label: "实际发车" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.leaveTime
                                    ? scope.row.leaveTime.slice(11, 16)
                                    : ""
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "planArriveTime", label: "计划到达" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.planArriveTime.slice(11, 16)) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "arriveTime", label: "实际到达" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.arriveTime
                                    ? scope.row.arriveTime.slice(11, 16)
                                    : ""
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "runMile", label: "里程" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "natureState", label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("filterNatureState")(
                                    scope.row.natureState
                                  )
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "详情" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.$store.state.menu.nowMenuList.indexOf("查看") >=
                            0
                              ? _c(
                                  "el-button",
                                  {
                                    style: { "font-size": "16px" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openDiaLog("查看", scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("查看")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.show
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.show,
                width: "600px",
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.show = $event
                },
                close: _vm.closeDialog
              }
            },
            [
              _vm.title == "查看"
                ? _c(
                    "div",
                    { staticClass: "table-box" },
                    [
                      _c("div", { staticClass: "refer-header" }, [
                        _c("label", [
                          _vm._v("里程数：" + _vm._s(_vm.form.runMile))
                        ]),
                        _c("label", [_vm._v("趟次：" + _vm._s(_vm.form.times))])
                      ]),
                      _c(
                        "el-table",
                        {
                          staticClass: "basicBox dispatchMonitoring",
                          attrs: {
                            stripe: "",
                            data: _vm.referTable,
                            "cell-style": { color: "#000", fontSize: "16px" },
                            "header-cell-style": {
                              background: "#FAFAFA",
                              color: "#999999"
                            }
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "serialNo", label: "序号" }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "stationName",
                              "show-overflow-tooltip": "",
                              label: "站点名称"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "arriveTime", label: "到达时间" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.arriveTime
                                              ? scope.row.arriveTime.slice(
                                                  11,
                                                  16
                                                )
                                              : ""
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1736270668
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "leaveTime", label: "发车时间" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.leaveTime
                                              ? scope.row.leaveTime.slice(
                                                  11,
                                                  16
                                                )
                                              : ""
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              583280300
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "stopoverSecond", label: "停留时间" }
                          }),
                          _vm._v(" >")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.title == "补录行程"
                ? _c(
                    "div",
                    [
                      _c("addRecordTripDialog", {
                        attrs: {
                          busLineList: _vm.lineOptions,
                          drivingStateList: _vm.drivingStateOptions
                        },
                        on: {
                          closeDia: _vm.closeDialog,
                          submitTripDiaFrom: _vm.submitTripRecord
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.title == "确认行程"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          staticClass: "demo-form form-search",
                          attrs: {
                            model: _vm.form,
                            rules: _vm.rules,
                            "status-icon": "",
                            "label-position": "left",
                            "label-width": "90px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否完成:",
                                prop: "drivingState"
                              }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.drivingState,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "drivingState", $$v)
                                    },
                                    expression: "form.drivingState"
                                  }
                                },
                                _vm._l(_vm.drivingStateOptions, function(item) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: item.value,
                                      attrs: { label: item.value }
                                    },
                                    [_vm._v(_vm._s(item.content))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "司机:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  ref: "select_element",
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    "filter-method": _vm.vehicleMethodFun,
                                    placeholder: "请输入司机"
                                  },
                                  model: {
                                    value: _vm.form.realityDriver,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "realityDriver", $$v)
                                    },
                                    expression: "form.realityDriver"
                                  }
                                },
                                _vm._l(_vm.driversOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.credentialsNo,
                                    attrs: {
                                      label: item.driverName,
                                      value: item.credentialsNo
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm.form.recordIds.length === 1 &&
                          _vm.form.drivingState === 1
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: { label: "里程数:", prop: "runMile" }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: { min: 0, size: "small" },
                                    model: {
                                      value: _vm.form.runMile,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "runMile", $$v)
                                      },
                                      expression: "form.runMile"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注:", prop: "remark" } },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  "margin-top": "10px",
                                  width: "250px"
                                },
                                attrs: { type: "textarea", rows: 3 },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "buttonGroup text-right" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "default" },
                                  on: { click: _vm.closeDialog }
                                },
                                [_vm._v("取 消")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: { click: _vm.confirmSchedule }
                                },
                                [_vm._v("确 定")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }